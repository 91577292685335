import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CloseCardButton } from '../common/blocks/CloseCardButton';
import { Label } from '../common/elements/Label';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { AddButton } from '../common/elements/AddButton';
import { ConfirmBox } from '../common/elements/ConfirmBox';
import { TextInput } from '../common/elements/TextInput';
import { ReactComponent as TrashImg } from '../../images/trash.svg';
import { ReactComponent as GroupImg } from '../../images/icon-group.svg';
import { actionCreators as groupActions } from '../../reducer/groups';
import { actionCreators as travellerActionCreators } from '../../reducer/traveller';
import { actionCreators as parameterActionCreators } from '../../reducer/parameters';
import {
	actionCreators as studentActions,
	hasLoadedStudentTickets,
	hasLoadedStudentBearers,
	selectStudentTickets,
	isLoadingStudentTickets,
	isUpdatingStudent,
	getStudentBearers,
	isTravelCardSmartPhone,
	getReferredAccount,
	getReferredAccountTickets,
	canDeleteGroup,
} from '../../reducer/students';
import {
	actionCreators as orderActions,
	isSchoolTicketCancellable,
} from '../../reducer/school/orders';
import {
	hasCreateStudentBearerError,
	isLoadingStudentBearers,
	hasDeleteGroupStudentError,
	getStudentById,
	isLoadingGroupDeleteStudent,
} from '../../reducer';
import withTextContext from '../../utils/withTextContext';
import { Loading } from '../common/blocks/Loading';
import { Validation, regexToInputPattern } from '../../utils/validation';
import { LinkButton } from '../common/elements/LinkButton';
import { Error } from '../common/elements/Error';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { sanitizeCmsHtml } from '../../texts';
import { PopUpBox, PopupContainer } from '../common/elements/PopUpBox';
import { NextButton } from '../common/elements/NextButton';
import Ticket from './Ticket';
import { isEmpty } from 'lodash';
import { BearerInfo } from '../common/blocks/BearerInfo';
import { ErrorMessageHandler } from '../common/blocks/ErrorMessageHandler';
import { StudentDetailsEditableField } from './StudentDetailsEditableField';

export const TYPES = {
	NAME: 'name',
	REFERREDACCOUNT: 'referredAccount',
	PERSONALIDENTITYNUMBER: 'personalIdentityNumber',
};

class StudentPopup extends Component {
	state = {
		showRegisterBearer: false,
		showDeleteWarning: false,
		serial: '',
		name: '',
		bearerIdToDelete: '',
		hasError: false,
		updateError: '',
		confirmDeleteGroup: null,
		showConfirmation: false,
		closePopupOnConfirmationClose: false,
		showGroupDeleteValidation: false,
		showRemoveReferredAccount: false,
		errorMessageReferral: '',
		phoneNumberRefferedAccount: '',
		phoneNumberRefferedAccountValid: undefined,
		nameEditable: false,
		personalIdentityNumberEditable: false,
		referredAccountEditable: false,
		newName: '',
		newPersonalIdentityNumber: '',
		newReferredAccount: '',
		updateStudentErrorMessage: '',
	};

	componentDidMount() {
		const {
			getStudent,
			travellerId,
			hasLoadedBearers,
			hasLoadedTickets,
		} = this.props;
		const student = getStudent(travellerId);

		this.setState({
			name: `${student.firstName} ${student.lastName}`,
			nameValid: true,
		});

		if (!hasLoadedTickets(travellerId))
			this.props.getStudentTickets(travellerId);

		if (!hasLoadedBearers(travellerId))
			this.props.loadStudentBearers(travellerId);

		this.props.getLoanTransactions(travellerId);
	}

	removeStudentFromGroup = async () => {
		const { travellerId } = this.props;
		const { groupId } = this.state.confirmDeleteGroup;

		await this.props.removeStudentFromGroup(groupId, travellerId);
		if (!this.props.hasDeleteGroupStudentError) {
			this.toggleConfirmationCard({ title: 'Elev borttagen' });
		}
	};

	allowGroupDeletion = travellerId => {
		const { getStudent, canDeleteGroup } = this.props;
		const student = getStudent(travellerId);

		return canDeleteGroup([student]);
	};

	toggleConfirmationCard = ({
		title,
		closePopupOnConfirmationClose = true,
	} = {}) => {
		const {
			showConfirmation,
			closePopupOnConfirmationClose: shouldClosePopup,
		} = this.state;
		const { closePopup } = this.props;

		if (showConfirmation && shouldClosePopup) closePopup();

		this.setState(state => ({
			showConfirmation: !state.showConfirmation,
			showDeleteWarning: false,
			confirmDeleteGroup: null,
			confirmationTitle: title,
			closePopupOnConfirmationClose,
		}));
	};

	toggleGroupDelete = (groupId, travellerId) => {
		if (this.allowGroupDeletion(travellerId)) {
			this.setState({ confirmDeleteGroup: { groupId, travellerId } });
		} else {
			this.setState({ showGroupDeleteValidation: true });
		}
	};

	renderConfirmDelete = () => {
		const { closePopup, isLoadingGroupDeleteStudent, texts } = this.props;
		return (
			<PopUpBox>
				{isLoadingGroupDeleteStudent ? (
					<InnerContainer>
						<CloseCardButton onClick={closePopup} />
						<Loading />
					</InnerContainer>
				) : (
					<>
						<CloseCardButton onClick={closePopup} />
						<InnerContainer>
							<ConfirmDeleteText>
								<h1>Vänta lite!</h1>
								<div
									dangerouslySetInnerHTML={sanitizeCmsHtml(
										texts.school.studentpopup.deletegroup.deleteinformation
									)}
								/>
							</ConfirmDeleteText>
							<ConfirmDeleteButtons>
								<CancelDeleteGroupButton
									value="Tillbaka"
									onClick={() => this.setState({ confirmDeleteGroup: null })}
								>
									Tillbaka
								</CancelDeleteGroupButton>
								<ConfirmDeleteGroupButton
									value="Ta bort ur grupp"
									onClick={this.removeStudentFromGroup}
								>
									Ta bort ur grupp
								</ConfirmDeleteGroupButton>
							</ConfirmDeleteButtons>
						</InnerContainer>
					</>
				)}
			</PopUpBox>
		);
	};

	registerBearer = async () => {
		const { name, serial } = this.state;
		const { travellerId } = this.props;

		try {
			await this.props.createBearer(name, serial, travellerId);
			this.setState({
				showRegisterBearer: false,
			});
		} catch (error) {
			this.setState({ hasError: true });
		}
	};

	registerReferralAccount = async () => {
		const { getStudent, travellerId } = this.props;
		const { phoneNumberRefferedAccount } = this.state;

		let student = getStudent(travellerId);
		try {
			await this.props.connectAccount(
				student.contactId,
				phoneNumberRefferedAccount
			);
			const referredAccount = this.props.getReferredAccount(travellerId);
			this.props.getAllReferredAccountTickets([
				referredAccount.referredAccountTravellerId,
			]);
			this.props.getStudentTickets(travellerId);
			this.toggleConfirmationCard({
				title: 'Reseapp är nu kopplad',
			});
		} catch (error) {
			this.setState({ hasError: true, errorMessageReferral: error.message });
		}
	};

	deleteBearer = async () => {
		const { bearerIdToDelete } = this.state;
		const { travellerId } = this.props;

		try {
			await this.props.deleteStudentBearer(bearerIdToDelete, travellerId);
			this.toggleConfirmationCard({
				title: 'Resekortet har tagits bort',
			});
			// reload tickets to update tickets connected to removed bearer
			this.props.getStudentTickets(travellerId);
		} catch {
			this.setState({ showDeleteWarning: false, deleteError: true });
		}
	};

	removeReferredAccount = async () => {
		const { travellerId, getStudent } = this.props;
		const { contactId } = getStudent(travellerId);
		let showRemoveReferredAccountError = false;
		try {
			await this.props.deleteReferredAccounts(contactId, travellerId);
			this.props.getTicketsForStudentIds([travellerId]);
			this.toggleConfirmationCard({
				title: 'Koppling har tagits bort',
			});
		} catch {
			showRemoveReferredAccountError = true;
		}
		this.setState({
			showRemoveReferredAccount: false,
			removeReferredAccountError: showRemoveReferredAccountError,
		});
	};

	onShowDeleteWarning = bearerId => () => {
		this.setState({
			showDeleteWarning: true,
			bearerIdToDelete: bearerId,
		});
	};

	onHideDeleteWarning = () => this.setState({ showDeleteWarning: false });

	onShowRemoveReferredAccount = () => {
		this.setState({
			showRemoveReferredAccount: true,
		});
	};

	onHideRemoveReferredAccount = () =>
		this.setState({ showRemoveReferredAccount: false });

	toggle = () => {
		const { getStudent, travellerId } = this.props;
		const student = getStudent(travellerId);

		this.setState(state => ({
			showRegisterBearer: !state.showRegisterBearer,
			serial: '',
			serialValid: undefined,
			name: `${student.firstName} ${student.lastName}`,
		}));
	};

	toggleReferralAccount = () => {
		this.setState(state => ({
			showRegisterReferredAccount: !state.showRegisterReferredAccount,
		}));
	};

	handleChange = target => {
		this.setState({
			[target.id]: target.value,
			updateError: '',
			hasError: false,
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
		});
	};

	handleBlur = ({ target }) =>
		this.setState({
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
		});

	validators = {
		name: value => value.length > 1,
		serial: value => value.length > 1 && Validation.serial.test(value),
		phoneNumberRefferedAccount: value =>
			value.length > 1 && Validation.mobile.test(value),
	};

	isFieldValid = (field, value) => this.validators[field](value);

	formattedBearers = bearers =>
		(bearers || []).map(b => ({ title: b.name, id: b.id, serial: b.serial }));

	enableEditing = type => {
		let nameEditable = false;
		let personalIdentityNumberEditable = false;
		let referredAccountEditable = false;

		if (type === TYPES.NAME) nameEditable = true;
		if (type === TYPES.REFERREDACCOUNT) referredAccountEditable = true;
		if (type === TYPES.PERSONALIDENTITYNUMBER) personalIdentityNumberEditable = true;

		this.setState({
			nameEditable,
			personalIdentityNumberEditable,
			referredAccountEditable,
		});
	};

	handleInputChange = changedInput => {
		this.setState({
			[changedInput.name]: changedInput.value,
		});
	};

	onSubmit = async submittedData => {
		const { getStudent, travellerId } = this.props;
		const student = getStudent(travellerId);
		let updateStudentErrorMessage = '';

		if (submittedData.type === 'name') {
			const name = submittedData.value;
			const fullName = name.split(" ");

			if (fullName.length < 2) {
				updateStudentErrorMessage = "NAME_NOT_COMPLETE";
			} else {
				const firstName = name.substring(0, name.indexOf(' '));
				const lastName = name.substring(name.indexOf(' ') + 1);

				try {
					await this.props.updateCustomerByTravellerId(
						travellerId,
						{
							firstName,
							lastName,
						},
					);
				} catch (error) {
					updateStudentErrorMessage = error.message;
				}
			}
		} else if (submittedData.type === 'personalIdentityNumber') {
			try {
				await this.props.updateCustomerByTravellerId(
					travellerId,
					{
						[submittedData.type]: submittedData.value,
					}
				);
			} catch (error) {
				updateStudentErrorMessage = error.message;
			}
		} else if (submittedData.type === 'referredAccount') {
			try {
				const traveller = await this.props.updateReferredAccount(student.contactId, submittedData.value);
				if (traveller.referredAccount != null) {
					await this.props.updateReferredAccountTickets(traveller.referredAccount.referredAccountTravellerId);
				}
			} catch (error) {
				updateStudentErrorMessage = error.message;
			}
		}

		this.setState({
			updateStudentErrorMessage,
		});
	};

	disableEditing = () => {
		this.setState({
			nameEditable: false,
			personalIdentityNumberEditable: false,
			referredAccountEditable: false,
		});
	};

	disableSaveButton = (oldValue, newValue) =>
		!newValue || newValue.length === 0 || oldValue === newValue;

	render() {
		const {
			travellerId,
			getStudent,
			closePopup,
			isLoadingStudentBearers,
			hasDeleteGroupStudentError,
			selectStudentTickets,
			getBearers,
			isLoadingStudentTickets,
			getReferredAccount,
			getReferredAccountTickets,
			isSchoolTicketCancellable,
			isUpdatingStudent,
		} = this.props;
		const {
			showConfirmation,
			confirmDeleteGroup,
			showGroupDeleteValidation,
			nameValid,
			showDeleteWarning,
			serialValid,
			phoneNumberRefferedAccount,
			phoneNumberRefferedAccountValid,
			showRemoveReferredAccount,
			errorMessageReferral,
			nameEditable,
			personalIdentityNumberEditable,
			referredAccountEditable,
			newName,
			newPersonalIdentityNumber,
			newReferredAccount,
			updateStudentErrorMessage,
		} = this.state;

		let student = getStudent(travellerId);
		if (!student) return null;

		const bearers = getBearers(travellerId) || [];
		let formattedBearers = this.formattedBearers(bearers);
		let tickets = selectStudentTickets(travellerId);

		const referredAccount = getReferredAccount(student.travellerId);
		if (referredAccount) {
			const referredAccountTickets = getReferredAccountTickets(
				referredAccount.referredAccountTravellerId
			);
			tickets = tickets.concat(referredAccountTickets);
		}

		if (showConfirmation) {
			const { confirmationTitle } = this.state;
			return (
				<PopupContainer>
					<ConfirmationCard
						title={confirmationTitle}
						onClick={this.toggleConfirmationCard}
					/>
				</PopupContainer>
			);
		}

		if (showDeleteWarning) {
			return (
				<ConfirmBox
					title="Vänta lite!"
					info={
						<>
							<p>Är du säker på att du vill ta bort resekortet?</p>
							<ul>
								<li>
									Resekortet raderas från eleven och eleven kan inte längre resa
									på resekortet
								</li>
								<li>
									Finns det aktiva biljetter på resekortet kommer dessa läggas
									på elevens profil och kan kopplas till ett nytt resekort eller
									reseapp.
								</li>
							</ul>
						</>
					}
					onContinue={this.deleteBearer}
					onClose={this.onHideDeleteWarning}
					onBack={this.onHideDeleteWarning}
					confirmButtonText="Ta bort resekort"
				/>
			);
		}

		if (showRemoveReferredAccount) {
			return (
				<ConfirmBox
					title="Vänta lite!"
					info={
						<>
							<p>
								Är du säker på att du vill ta bort koppling mellan elev och
								privatkonto?
							</p>
						</>
					}
					onContinue={this.removeReferredAccount}
					onClose={this.onHideRemoveReferredAccount}
					onBack={this.onHideRemoveReferredAccount}
					confirmButtonText="Ta bort koppling"
				/>
			);
		}

		if (confirmDeleteGroup !== null) return this.renderConfirmDelete();

		if (isLoadingStudentBearers || isUpdatingStudent) {
			return (
				<PopUpBox>
					<CloseCardButton onClick={closePopup} />
					<InnerContainer>
						<Loading />
					</InnerContainer>
				</PopUpBox>
			);
		}

		const fullName = `${student.firstName} ${student.lastName}`

		return (
			<PopUpBox>
				<CloseCardButton onClick={closePopup} />
				<InnerContainer>
					<StudentDetailsEditableField
						type={TYPES.NAME}
						currentValue={fullName}
						enableEdit={this.enableEditing}
						value={newName}
						isEditable={nameEditable}
						handleChange={this.handleInputChange}
						changeDisabled={personalIdentityNumberEditable || referredAccountEditable}
						submitDisabled={
							this.disableSaveButton(
								fullName,
								newName,
							)
						}
						handleSubmit={this.onSubmit}
						onBackClick={this.disableEditing}
					/>

					<Line />

					<StudentDetailsEditableField
						type={TYPES.PERSONALIDENTITYNUMBER}
						label={'Personnummer'}
						currentValue={student.personalIdentityNumber}
						enableEdit={this.enableEditing}
						value={newPersonalIdentityNumber}
						isEditable={personalIdentityNumberEditable}
						handleChange={this.handleInputChange}
						changeDisabled={referredAccountEditable || nameEditable}
						submitDisabled={
							this.disableSaveButton(
								student.personalIdentityNumber,
								newPersonalIdentityNumber,
							)
						}
						handleSubmit={this.onSubmit}
						onBackClick={this.disableEditing}
					/>

					{student.homeCounty && (
						<InfoWrapper>
							<span>Hemkommun</span>
							<span>{student.homeCounty}</span>
						</InfoWrapper>
					)}
					{this.state.updateError && (
						<UpdateMessage>
							<div style={{ color: '#ff0000' }}>{this.state.updateError}</div>
						</UpdateMessage>
					)}
					<InfoWrapper>
						{referredAccount ? (
							referredAccount.referredAccountPhoneNumber ? (
								<>
									<div style={{ width: "100%" }} >
										<StudentDetailsEditableField
											type={TYPES.REFERREDACCOUNT}
											label={'Reseapp'}
											currentValue={referredAccount.referredAccountPhoneNumber}
											enableEdit={this.enableEditing}
											value={newReferredAccount}
											isEditable={referredAccountEditable}
											handleChange={this.handleInputChange}
											changeDisabled={personalIdentityNumberEditable || nameEditable}
											submitDisabled={
												this.disableSaveButton(
													referredAccount.referredAccountPhoneNumber,
													newReferredAccount,
												)
											}
											handleSubmit={this.onSubmit}
											onBackClick={this.disableEditing}
											handleShowRemove={this.onShowRemoveReferredAccount}
										/>
									</div>
								</>
							) : (
								<span>Ja</span>
							)
						) : (
							<>
								<span>Reseapp</span>
								<NoData>Nej</NoData>
							</>
						)}
					</InfoWrapper>
					{this.state.removeReferredAccountError && (
						<ErrorMessage>
							Det gick inte att ta bort koppling. Vänligen försök igen.
						</ErrorMessage>
					)}

					{updateStudentErrorMessage && (
						ErrorMessageHandler(updateStudentErrorMessage)
					)}

					{this.state.showRegisterReferredAccount ? (
						<RegisterReferralAccount>
							<div className="form-group">
								<TextInput
									type="tel"
									value={phoneNumberRefferedAccount}
									handleChange={this.handleChange}
									className="form-control"
									id="phoneNumberRefferedAccount"
									placeholder="46712345678"
								/>
								{phoneNumberRefferedAccount.length > 1 &&
									!phoneNumberRefferedAccountValid && (
										<ErrorMessage>
											Vänligen ange telefonnummer i formatet '46712345678'.
										</ErrorMessage>
									)}
							</div>

							{this.state.hasError && ErrorMessageHandler(errorMessageReferral)}

							<ButtonWrapper>
								<CancelRegisterReferralAccountButton
									onClick={this.toggleReferralAccount}
								>
									Avbryt
								</CancelRegisterReferralAccountButton>
								<RegisterReferralAccountButton
									onClick={this.registerReferralAccount}
									disabled={!phoneNumberRefferedAccountValid}
								>
									Koppla konto
								</RegisterReferralAccountButton>
							</ButtonWrapper>
						</RegisterReferralAccount>
					) : (
						!referredAccount && (
							<ButtonWrapper>
								<AddButton onClick={this.toggleReferralAccount}>
									<span>Koppla konto</span>
								</AddButton>
							</ButtonWrapper>
						)
					)}
					<Line />
					{<h4>Resekort</h4>}
					{isEmpty(bearers) && <NoData>Inga registrerade resekort</NoData>}
					{bearers.map((bearer, idx) => (
						<IconContainer key={idx}>
							<BearerInfo bearer={bearer} />
							{!isTravelCardSmartPhone(bearer) && (
								<TrashIcon
									className="svg-icon"
									role="button"
									onClick={this.onShowDeleteWarning(bearer.id)}
								/>
							)}
						</IconContainer>
					))}
					{this.state.deleteError && (
						<ErrorMessage>
							Det gick inte att ta bort resekortet. Vänligen försök igen.
						</ErrorMessage>
					)}
					{this.state.showRegisterBearer ? (
						<RegisterBearerContainer>
							<div>
								<h5>Lägg till resekort</h5>
							</div>
							{this.state.hasError && (
								<ErrorMessage>
									Det gick inte att registrera resekortet. Vänligen försök igen.
								</ErrorMessage>
							)}
							<div className="form-group">
								<Label htmlFor="serial">Kortnummer*</Label>
								<TextInput
									type="text"
									value={this.state.serial}
									handleChange={this.handleChange}
									className="form-control"
									id="serial"
									placeholder=""
									pattern={regexToInputPattern(Validation.serial)}
									validateOnChange={true}
								/>
								{typeof serialValid !== 'undefined' && !serialValid && (
									<ErrorMessage>Kortnumret är inte rätt ifyllt</ErrorMessage>
								)}
							</div>
							<div className="form-group">
								<Label htmlFor="name">Namn på kort* </Label>{' '}
								<TextInput
									type="text"
									value={this.state.name}
									handleChange={this.handleChange}
									className="form-control"
									id="name"
									placeholder=""
									maxLength="30"
									onBlur={this.handleBlur}
								/>
								{typeof nameValid !== 'undefined' && !nameValid && (
									<ErrorMessage>Namnet är inte rätt ifyllt</ErrorMessage>
								)}
							</div>
							<ButtonWrapper>
								<CancelRegisterBearerButton onClick={this.toggle}>
									Avbryt
								</CancelRegisterBearerButton>
								<RegisterBearerButton
									onClick={this.registerBearer}
									disabled={!serialValid || !nameValid}
								>
									Spara
								</RegisterBearerButton>
							</ButtonWrapper>
						</RegisterBearerContainer>
					) : (
						<ButtonWrapper>
							<AddButton onClick={this.toggle}>
								<span>Lägg till resekort</span>
							</AddButton>
						</ButtonWrapper>
					)}
					<Line />
					<h4>Biljetter</h4>
					{isLoadingStudentTickets && <Loading text="Laddar biljetter..." />}
					{isEmpty(tickets) ? (
						<NoData>Eleven har inga biljetter att visa</NoData>
					) : (
						tickets.map(x => (
							<Ticket
								key={x.id}
								product={x}
								bearers={formattedBearers}
								onToggleConfirmationCard={this.toggleConfirmationCard}
								travellerId={travellerId}
								closePopup={this.props.closePopup}
								isReferredTicket={travellerId !== x.travellerId}
								isCancellable={isSchoolTicketCancellable(x.id)}
							/>
						))
					)}{' '}
					<Line />
					<h4>Grupper</h4>
					{student.groupInformation.map((group, idx) => (
						<Fragment key={idx}>
							{showGroupDeleteValidation && (
								<DeleteGroupValidationText>
									Eleven kan ej tas bort ur denna grupp på grund av en aktiv
									biljett
								</DeleteGroupValidationText>
							)}
							<IconContainer>
								<GroupIcon />
								<GroupName>{group.groupName}</GroupName>
								<LinkButton
									onClick={() =>
										this.toggleGroupDelete(group.groupId, student.travellerId)
									}
								>
									<TrashIcon />
								</LinkButton>
							</IconContainer>
							{hasDeleteGroupStudentError && <Error />}
						</Fragment>
					))}
					{isEmpty(student.groupInformation) && (
						<NoData>Eleven tillhör ingen grupp</NoData>
					)}
					<Line />
				</InnerContainer>
			</PopUpBox>
		);
	}
}

const RegisterBearerContainer = styled.div`
	margin-top: 1em;
`;

const RegisterReferralAccount = styled.div`
	margin-top: 1em;
`;

const RegisterBearerButton = styled(NextButton)`
	max-width: 250px;
	width: 48%;
`;

const RegisterReferralAccountButton = styled(NextButton)`
	max-width: 250px;
	width: 48%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 1em;
`;

const CancelRegisterBearerButton = styled(SecondaryButton)`
	display: block;
	width: 48%;
	font-weight: normal;
	background-color: white;
	border: 1px solid #645b5b;
	color: #645b5b;
`;

const CancelRegisterReferralAccountButton = styled(SecondaryButton)`
	display: block;
	width: 48%;
	font-weight: normal;
	background-color: white;
	border: 1px solid #645b5b;
	color: #645b5b;
`;

const UpdateMessage = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 0.5em;
`;

const NoData = styled.div`
	font-style: italic;
	color: ${props => props.theme.light_grey};
`;

const IconContainer = styled.div`
	text-align: left;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1em;
`;

const GroupName = styled.div`
	flex-grow: 1;
`;

const GroupIcon = styled(GroupImg)`
	width: 25px;
	margin-right: 15px;
`;

const ErrorMessage = styled.p`
	margin-top: 0.5em;
	color: crimson;
	text-align: left;
`;

const TrashIcon = styled(TrashImg)`
	margin-left: 10px;

	&:hover {
		cursor: pointer;
	}
`;

const InnerContainer = styled.div`
	width: 380px;
	max-width: 100%;
`;
const Line = styled.div`
	border-bottom: 1px solid #ccc;
	margin-top: 2em;
	margin-bottom: 2em;
`;

const InfoWrapper = styled.div`
	padding-bottom: 1.5em;
	padding-top: 1.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;

	span:first-child,
	label {
		font-weight: 700;
	}
`;

const DeleteGroupValidationText = styled.p`
	color: rgb(255, 0, 0);
	font-family: ${props => props.theme.text};
	font-size: 12px;
	font-style: italic;
`;

const ConfirmDeleteText = styled.div`
	h1 {
		margin: 0 0 25px;
	}

	ul {
		text-align: left;
		padding-inline-start: 18px;
	}
`;

const ConfirmDeleteButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 64px;
`;

const ConfirmDeleteGroupButton = styled(SecondaryButton)`
	display: block;
	width: 48%;
	padding: 10px 30px;
	margin-bottom: 1em;
	font-weight: normal;
`;

const CancelDeleteGroupButton = styled(ConfirmDeleteGroupButton)`
	background-color: white;
	border: 1px solid #645b5b;
	color: #645b5b;
`;

function mapStateToProps(state) {
	return {
		getStudent: getStudentById(state),
		selectStudentTickets: selectStudentTickets(state),
		hasLoadedTickets: hasLoadedStudentTickets(state),
		hasLoadedBearers: hasLoadedStudentBearers(state),
		getBearers: getStudentBearers(state),
		isLoadingStudentBearers: isLoadingStudentBearers(state),
		hasCreateBearerError: hasCreateStudentBearerError(state),
		isLoadingGroupDeleteStudent: isLoadingGroupDeleteStudent(state),
		hasDeleteGroupStudentError: hasDeleteGroupStudentError(state),
		isLoadingStudentTickets: isLoadingStudentTickets(state),
		isUpdatingStudent: isUpdatingStudent(state),
		getReferredAccount: getReferredAccount(state),
		getReferredAccountTickets: getReferredAccountTickets(state),
		isSchoolTicketCancellable: isSchoolTicketCancellable(state),
		canDeleteGroup: canDeleteGroup(state),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...groupActions,
		...studentActions,
		...travellerActionCreators,
		...parameterActionCreators,
		...orderActions,
	})(StudentPopup)
);
